import React, { useContext, useMemo, useState } from 'react';
import { FaGift } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { differenceInDays, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

// Interface import
import { IApplicationState } from '../../store';
import { IAuthState } from '../../store/modules/auth/types';

// Style import
import { Container, CardList } from './styles';

// Component import
import Alert from '../../components/Alert';
import Button from '../../components/Form/Button';
import Card from '../../components/Card';
import { ICardState } from '../../store/modules/card/types';

const Cards: React.FC = () => {
  // History service
  const history = useHistory();

  // Get translation function
  const { t } = useTranslation();

  // Theme Context
  const themeContext = useContext(ThemeContext);

  // Global states
  const user_data = useSelector<IApplicationState>(
    state => state.auth,
  ) as IAuthState;
  const user_cards = useSelector<IApplicationState>(
    state => state.card,
  ) as ICardState;

  // Local states
  const [showArchived, setShowArchived] = useState(false);

  // Calculates the expiration
  const trial_difference = Math.abs(
    differenceInDays(
      new Date(),
      parseISO(String(user_data.user?.trial_expires_at)),
    ),
  );

  // If user card was not created, redirect to dashboard
  if (
    !user_data?.user?.card_created_at ||
    !user_cards.list.length ||
    !user_data?.user?.gift_opened_at
  ) {
    history.push('/dashboard');
  }

  // Archived count
  const archivedCount = useMemo(() => {
    return user_cards.list.filter(card => card.archived === true).length;
  }, [user_cards]);

  return (
    <Container>
      {user_data.user?.card_created_at || user_data.user?.trial_expires_at ? (
        <>
          <Alert type="warning">
            <MdDateRange size={36} color={themeContext.font_warning} />
            <div>
              <strong>
                {t(
                  '@card_list/SYSTEM_TITLE',
                  'Sistema de monitoramento do cartão',
                )}
              </strong>
              {' - '}
              {t(
                '@card_list/FINISH_MONITOR',
                'Seu período de degustação se encerrará em',
              )}
              <span>{` ${trial_difference + 1} ${
                trial_difference > 0 ? 'dias' : 'dia'
              }`}</span>
            </div>
          </Alert>
        </>
      ) : (
        <Alert type="warning">
          <FaGift size={36} color="#FC7D35" />
          <div>
            {t(
              '@card_list/CARD_NOT_CREATED',
              'Você ainda não criou seu Cartão Digital. Clique no botão ao lado e crie seu cartão. É rápido e fácil !!!',
            )}
          </div>
          <Button colorType="primary" onClick={() => history.push('/card')}>
            {t('@card_list/CREATE_CARD', 'CRIAR CARTÃO DIGITAL')}
          </Button>
        </Alert>
      )}
      <CardList>
        {user_cards.list
          .filter(card => !card.archived)
          .map(card => (
            <Card id={card.id} />
          ))}
      </CardList>
      {!!archivedCount && (
        <div className="archived">
          <Button
            colorType="primary"
            onClick={() => setShowArchived(!showArchived)}
          >
            {showArchived
              ? `${t(
                  '@card_list/HIDE_ARCHIVED_CARDS',
                  'Ocultar Cartões Arquivados',
                )} (${archivedCount})`
              : `${t(
                  '@card_list/SHOW_ARCHIVED_CARDS',
                  'Exibir Cartões Arquivados',
                )} (${archivedCount})`}
          </Button>
        </div>
      )}
      {showArchived && !!archivedCount && (
        <CardList>
          {user_cards.list
            .filter(card => card.archived)
            .map(card => (
              <Card id={card.id} />
            ))}
        </CardList>
      )}
    </Container>
  );
};

export default Cards;
