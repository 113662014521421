import styled from 'styled-components';
import { rgba } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .upper-container {
    display: flex;
    align-self: stretch;
    align-items: flex-start;
    justify-content: center;
    gap: 1.25rem;

    > div:last-child {
      flex: 1;
    }

    .access-graph {
      display: flex;
      align-items: center;
      justify-content: center;
      align-self: stretch;
      margin: 1rem;
    }
  }

  .count {
    margin: 1.25rem 0;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
  }

  .form-data {
    width: 100%;
    background-color: ${({ theme }) => theme.background_high};
    display: flex;
    flex-direction: column;
    padding: 0.9375rem;
    margin: 0.625rem 0;
    border-radius: 0.3125rem;
    filter: ${({ theme }) =>
      `drop-shadow(0px 0.0625rem 0.125rem ${rgba(
        theme.background_low,
        0.25,
      )})`};

    .title {
      margin-bottom: 1.25rem;
    }
  }

  .select-dates {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: space-between;
    gap: 0.625rem;
  }

  .table-container {
    max-height: 21.25rem;
    overflow-y: auto;
    align-self: stretch;

    div {
      padding: 0.125rem 0.625rem;
      font-weight: 500;
      color: white;
    }

    .facebook {
      background-color: #3b5998;
    }
    .whatsapp {
      background-color: #128c7e;
    }
    .twitter {
      background-color: #1da1f2;
    }
    .youtube {
      background-color: #da3535;
    }
    .instagram {
      background-color: #f56040;
    }
    .linkedin {
      background-color: #0077b5;
    }
    .website {
      background-color: #999999;
    }
    .address {
      background-color: #ffae00;
    }
    .odd {
      background-color: #f9f9f9;
    }
    .even {
      background-color: #f4f4f4;
    }
  }

  .chart-container {
    overflow-x: auto;
    align-self: stretch;

    > div {
      min-width: 750px;
    }
  }

  @media screen and (max-width: 1200px) {
    .upper-container {
      flex-direction: column;

      > div {
        width: 100%;
      }
    }

    .select-dates {
      grid-template-columns: minmax(0, 1fr);
    }
  }

  @media screen and (max-width: 700px) {
    .count {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-template-columns: 1fr;
    }
  }
`;

export const AlertContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
  }

  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
`;

export const SocialCard = styled.div`
  color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.9375rem;
  margin: 0;
  border-radius: 0.3125rem;
  filter: ${({ theme }) =>
    `drop-shadow(0px 0.0625rem 0.125rem ${rgba(theme.background_low, 0.25)})`};

  &.facebook {
    background: #3b5998;
  }
  &.whatsapp {
    background: #128c7e;
  }
  &.twitter {
    background: #1da1f2;
  }
  &.youtube {
    background: #da3535;
  }
  &.instagram {
    background: #f56040;
  }
  &.linkedin {
    background: #0077b5;
  }
  &.website {
    background: #999999;
  }
  &.address {
    background: #ffae00;
  }
  &.other {
    background: #444444;
  }

  .title {
    font-size: 0.8125rem;
    flex: 1;
  }

  .value {
    font-weight: 600;
    font-size: 1.5rem;
    text-align: right;
  }
`;
