import React, { useCallback, useEffect, useState } from 'react';
import {
  MdLock,
  MdInfo,
  MdOpenWith,
  MdCheckBox,
  MdOpenInNew,
  MdWarning,
} from 'react-icons/md';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { DndProvider } from 'react-dnd-multi-backend';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Component import
import Alert from '../../../components/Alert';
import Button from '../../../components/Form/Button';
import Input from '../../../components/Form/Input';

// Module import
import { DragItem } from './DragItem';

// Style import
import { Container, ModelPreview, PreviewContainer, ModelData } from './styles';
import { Navigation } from '../styles';

// Store import
import { IApplicationState } from '../../../store';
import { IAuthState } from '../../../store/modules/auth/types';

// Service import
import api from '../../../services/api';

// Util import
import compileGoogleMapsAddressUrl from '../../../util/compileGoogleMapsAddressUrl';

// Hook import
import { useCard } from '../../../hooks/useCard';

// Card models import
import card_models from '../../../hooks/useCard/models';

// Interface
interface IFields {
  id: number;
  name: string;
  label: string;
  visible: boolean;
  value?: string;
  setVisible: (visible: boolean) => void;
  setOrder: (order: number) => void;
}

interface IAppOrder {
  [key: string]: number;
}

const CardSettings: React.FC = () => {
  // Local contexts
  const cardContext = useCard();
  const { selected_card_model } = cardContext;

  // Get translation function
  const { t } = useTranslation();

  // Global states
  const user_data = useSelector<IApplicationState>(
    state => state.auth,
  ) as IAuthState;

  // Local states
  const [order, setOrder] = useState<IFields[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [adjusted, setAdjusted] = useState<boolean>(false);

  // Order
  useEffect(() => {
    const app_order: IAppOrder = {
      phone: cardContext.phone_order,
      whatsapp: cardContext.whatsapp_order,
      email: cardContext.email_order,
      website: cardContext.website_order,
      address: cardContext.address_order,
      facebook: cardContext.facebook_order,
      instagram: cardContext.instagram_order,
      youtube: cardContext.youtube_order,
      linkedin: cardContext.linkedin_order,
      twitter: cardContext.twitter_order,
    };

    const state: IFields[] = [
      {
        id: 1,
        name: 'phone',
        label: t('@card_settings_setep/PHONE', 'Celular'),
        visible: cardContext.phone_show,
        value: user_data.user?.phone
          ? `tel:${(String(user_data.user?.phone).match(/\d+/g) || []).join(
              '',
            )}`
          : undefined,
        setVisible: cardContext.set_phone_show,
        setOrder: cardContext.set_phone_order,
      },
      {
        id: 2,
        name: 'whatsapp',
        label: t('@card_settings_setep/WHATSAPP', 'Whatsapp'),
        visible: cardContext.whatsapp_show,
        value: user_data.user?.whatsapp
          ? `https://wa.me/55${(
              String(user_data.user?.whatsapp).match(/\d+/g) || []
            ).join('')}`
          : undefined,
        setVisible: cardContext.set_whatsapp_show,
        setOrder: cardContext.set_whatsapp_order,
      },
      {
        id: 3,
        name: 'email',
        label: t('@card_settings_setep/EMAIL', 'E-mail'),
        visible: cardContext.email_show,
        value: user_data.person?.emails?.find(email => email.b_principal === 1)
          ?.s_email
          ? `mailto:${
              user_data.person?.emails?.find(email => email.b_principal === 1)
                ?.s_email
            }`
          : undefined,
        setVisible: cardContext.set_email_show,
        setOrder: cardContext.set_email_order,
      },
      {
        id: 4,
        name: 'website',
        label: t('@card_settings_setep/WEBSITE', 'Website'),
        visible: cardContext.website_show,
        value: user_data.user?.website,
        setVisible: cardContext.set_website_show,
        setOrder: cardContext.set_website_order,
      },
      {
        id: 5,
        name: 'address',
        label: t('@card_settings_setep/ADDRESS', 'Endereço'),
        visible: cardContext.address_show,
        value:
          user_data.user?.address_city ||
          user_data.user?.address_neighborhood ||
          user_data.user?.address_street ||
          user_data.user?.address_state ||
          user_data.user?.address_number ||
          user_data.user?.address_zipcode
            ? compileGoogleMapsAddressUrl({
                city: user_data.user?.address_city,
                neighborhood: user_data.user?.address_neighborhood,
                street: user_data.user?.address_street,
                state: user_data.user?.address_state,
                number: user_data.user?.address_number,
                zipcode: user_data.user?.address_zipcode,
              })
            : undefined,
        setVisible: cardContext.set_address_show,
        setOrder: cardContext.set_address_order,
      },
      {
        id: 6,
        name: 'facebook',
        label: t('@card_settings_setep/FACEBOOK', 'Facebook'),
        visible: cardContext.facebook_show,
        value: user_data.user?.facebook,
        setVisible: cardContext.set_facebook_show,
        setOrder: cardContext.set_facebook_order,
      },
      {
        id: 7,
        name: 'instagram',
        label: t('@card_settings_setep/INSTAGRAM', 'Instagram'),
        visible: cardContext.instagram_show,
        value: user_data.user?.instagram,
        setVisible: cardContext.set_instagram_show,
        setOrder: cardContext.set_instagram_order,
      },
      {
        id: 8,
        name: 'youtube',
        label: t('@card_settings_setep/YOUTUBE', 'Youtube'),
        visible: cardContext.youtube_show,
        value: user_data.user?.youtube,
        setVisible: cardContext.set_youtube_show,
        setOrder: cardContext.set_youtube_order,
      },
      {
        id: 9,
        name: 'linkedin',
        label: t('@card_settings_setep/LINKEDIN', 'Linkedin'),
        visible: cardContext.linkedin_show,
        value: user_data.user?.linkedin,
        setVisible: cardContext.set_linkedin_show,
        setOrder: cardContext.set_linkedin_order,
      },
      {
        id: 10,
        name: 'twitter',
        label: t('@card_settings_setep/TWITTER', 'Twitter'),
        visible: cardContext.twitter_show,
        value: user_data.user?.twitter,
        setVisible: cardContext.set_twitter_show,
        setOrder: cardContext.set_twitter_order,
      },
    ]
      .filter(item => item.value)
      .sort((a, b) => {
        if (app_order[a.name] < app_order[b.name]) return -1;
        if (app_order[a.name] > app_order[b.name]) return 1;
        return 0;
      });

    setOrder(state);
  }, [
    t,
    cardContext.address_order,
    cardContext.email_order,
    cardContext.phone_order,
    cardContext.facebook_order,
    cardContext.instagram_order,
    cardContext.linkedin_order,
    cardContext.twitter_order,
    cardContext.youtube_order,
    cardContext.website_order,
    cardContext.whatsapp_order,
    cardContext.address_show,
    cardContext.email_show,
    cardContext.phone_show,
    cardContext.facebook_show,
    cardContext.instagram_show,
    cardContext.linkedin_show,
    cardContext.twitter_show,
    cardContext.youtube_show,
    cardContext.website_show,
    cardContext.whatsapp_show,
    cardContext.set_address_show,
    cardContext.set_email_show,
    cardContext.set_phone_show,
    cardContext.set_facebook_show,
    cardContext.set_instagram_show,
    cardContext.set_linkedin_show,
    cardContext.set_twitter_show,
    cardContext.set_youtube_show,
    cardContext.set_website_show,
    cardContext.set_whatsapp_show,
    cardContext.set_address_order,
    cardContext.set_email_order,
    cardContext.set_phone_order,
    cardContext.set_facebook_order,
    cardContext.set_instagram_order,
    cardContext.set_linkedin_order,
    cardContext.set_twitter_order,
    cardContext.set_youtube_order,
    cardContext.set_website_order,
    cardContext.set_whatsapp_order,
    user_data.person?.emails,
    user_data.user?.address_city,
    user_data.user?.address_neighborhood,
    user_data.user?.address_number,
    user_data.user?.address_state,
    user_data.user?.address_street,
    user_data.user?.address_zipcode,
    user_data.user?.phone,
    user_data.user?.facebook,
    user_data.user?.instagram,
    user_data.user?.linkedin,
    user_data.user?.twitter,
    user_data.user?.youtube,
    user_data.user?.website,
    user_data.user?.whatsapp,
  ]);

  // Move item function
  const moveItem = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const draggedCard = order[dragIndex];
      const hoveredCard = order[hoverIndex];
      draggedCard.setOrder(hoverIndex);
      hoveredCard.setOrder(dragIndex);
    },
    [order],
  );

  // Render a item
  const renderItem = useCallback(
    (item: IFields, index: number) => {
      return (
        <DragItem
          key={item.id}
          index={index}
          id={item.id}
          name={item.name}
          label={item.label}
          value={item.value}
          visible={item.visible}
          setVisible={item.setVisible}
          moveItem={moveItem}
          setAdjusted={setAdjusted}
        />
      );
    },
    [moveItem],
  );

  // Submit function
  const handleSubmit = useCallback(async (): Promise<void> => {
    setLoading(true);
    try {
      const response = await api.post(
        'card/image',
        {
          card_template: cardContext.selected_card_model + 1,
          card_color: cardContext.selected_card_color,
          phone_show: cardContext.phone_show,
          phone_order: cardContext.phone_order,
          whatsapp_show: cardContext.whatsapp_show,
          whatsapp_order: cardContext.whatsapp_order,
          email_show: cardContext.email_show,
          email_order: cardContext.email_order,
          website_show: cardContext.website_show,
          website_order: cardContext.website_order,
          address_show: cardContext.address_show,
          address_order: cardContext.address_order,
          facebook_show: cardContext.facebook_show,
          facebook_order: cardContext.facebook_order,
          linkedin_show: cardContext.linkedin_show,
          linkedin_order: cardContext.linkedin_order,
          twitter_show: cardContext.twitter_show,
          twitter_order: cardContext.twitter_order,
          instagram_show: cardContext.instagram_show,
          instagram_order: cardContext.instagram_order,
          youtube_show: cardContext.youtube_show,
          youtube_order: cardContext.youtube_order,
        },
        {
          responseType: 'blob',
        },
      );

      const reader = new FileReader();
      reader.onload = () => {
        cardContext.set_card_preview(String(reader.result));

        setLoading(false);
        setAdjusted(false);
      };
      reader.readAsDataURL(response.data);
    } catch (err) {
      toast.error(
        t(
          '@general/CONNECTION_FAILURE_ERROR',
          'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
        ),
      );
      setLoading(false);
    }
  }, [t, cardContext]);

  // Initial submit
  useEffect(() => {
    handleSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Navigation>
        <nav>
          <Button
            disabled={
              cardContext.selected_card_model === undefined ||
              cardContext.selected_card_color === undefined ||
              loading
            }
            onClick={() => cardContext.set_active_page(1)}
            colorType="tertiary"
            size="small"
          >
            {t('@card_settings_setep/RETURN', 'Voltar')}
          </Button>
          <p>
            {t(
              '@card_settings_setep/DATA_DRAG_INSTRUCTIONS',
              'Se necessário, faça os ajustes abaixo, habilite ou desabilite qualquer informação ou reordene os campos.',
            )}
          </p>
          <Button
            colorType="tertiary"
            size="small"
            onClick={() => {
              cardContext.set_active_page(3);
              handleSubmit();
            }}
            disabled={loading || adjusted}
          >
            Avançar
          </Button>
        </nav>
      </Navigation>
      <main>
        <div className="content">
          <div className="box">
            <Alert type="info">
              <MdInfo size={36} color="#76bffa" />
              <div className="alertContent">
                <p>
                  {t(
                    '@card_settings_setep/INSTRUCTIONS_P1',
                    'Antes de prosseguir para a etapa de Ativação do Cartão Digital, valide a imagem e os links configurados.',
                  )}
                </p>
                <br />
                <p>
                  <MdCheckBox size={24} />{' '}
                  {t(
                    '@card_settings_setep/INSTRUCTIONS_P2',
                    'Clique neste ícone para habilitar/desabilitar os links do seu cartão.',
                  )}
                </p>
                <p>
                  <MdOpenWith size={24} />{' '}
                  {t(
                    '@card_settings_setep/INSTRUCTIONS_P3',
                    'Clique neste ícone e arraste para reordenar o posicionamento dos links.',
                  )}
                </p>
                <p>
                  <MdOpenInNew size={24} />{' '}
                  {t(
                    '@card_settings_setep/INSTRUCTIONS_P4',
                    'Clique neste ícone para testar individualmente cada link configurado.',
                  )}
                </p>
                <br />
                <p>
                  <MdWarning size={24} />{' '}
                  {t(
                    '@card_settings_setep/INSTRUCTIONS_P5',
                    'Ao realizar qualquer modificação nesta etapa, você porecisará clicar no botão Aplicar Ajustes (salvar), para poder avançar para próxima etapa.',
                  )}
                </p>
              </div>
            </Alert>
            <br />
            <div className="grid-1">
              <Input
                standalone
                label={t('@card_settings_setep/NAME', 'Nome')}
                variant="outlined"
                size="small"
                disabled
                value={user_data.user?.name || ''}
                endAdornment={<MdLock size={24} />}
              />
            </div>
            <div className="grid-2">
              <Input
                standalone
                label={t('@card_settings_setep/OCCUPATION', 'Profissão')}
                variant="outlined"
                size="small"
                disabled
                value={
                  user_data.person?.i_sexo === 0
                    ? user_data.person?.occupation?.s_nome_fem || ''
                    : user_data.person?.occupation?.s_nome || ''
                }
                endAdornment={<MdLock size={24} />}
              />

              <Input
                standalone
                label={t('@card_settings_setep/SPECIALTY', 'Especialização')}
                variant="outlined"
                size="small"
                disabled
                value={user_data.user?.specialty || ''}
                endAdornment={<MdLock size={24} />}
              />
            </div>

            <br />
            <DndProvider options={HTML5toTouch}>
              <div className="order-container">
                {order.map((item, i) => renderItem(item, i))}
              </div>
            </DndProvider>
          </div>

          <div className="box">
            <PreviewContainer>
              <ModelData>
                {adjusted && (
                  <div className="center-button">
                    <Button
                      type="submit"
                      colorType="primary"
                      size="large"
                      disabled={loading}
                      onClick={() => handleSubmit()}
                      loading={loading}
                    >
                      {t(
                        '@card_settings_setep/SAVE',
                        'Aplicar Ajustes (salvar)',
                      )}
                    </Button>
                  </div>
                )}
              </ModelData>
              <ModelPreview>
                {loading && (
                  <div>
                    {t(
                      '@card_settings_setep/LOADING_CARD_PREVIEW',
                      'Carregando pré-visualização...',
                    )}
                  </div>
                )}
                {!!cardContext.card_preview && (
                  <>
                    <img
                      src={String(cardContext.card_preview)}
                      alt={card_models.models[selected_card_model].name}
                    />
                    <div>
                      {t(
                        '@card_settings_setep/ILLUSTRATION',
                        'Imagem Ilustrativa',
                      )}
                    </div>
                  </>
                )}
              </ModelPreview>
            </PreviewContainer>
          </div>
        </div>
      </main>
    </Container>
  );
};

export default CardSettings;
