import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaIdCardAlt, FaInfoCircle, FaUsers } from 'react-icons/fa';
import {
  MdExitToApp,
  MdBuild,
  MdEqualizer,
  MdHome,
  MdPermIdentity,
  MdKeyboardArrowLeft,
  MdDehaze,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { ThemeContext } from 'styled-components';

// Component import
import Avatar from '../../components/Avatar';

// Store import
import { IApplicationState } from '../../store';
import { signOut } from '../../store/modules/auth/actions';

// Style import
import { Container } from './styles';

const Header: React.FC = () => {
  // Get dispatch hook
  const dispatch = useDispatch();

  // Get translation function
  const { t } = useTranslation();

  // Get router location
  const { pathname } = useLocation();

  // Global states
  const { user, person } = useSelector(
    (state: IApplicationState) => state.auth,
  );
  const cards = useSelector((state: IApplicationState) => state.card.list);

  // Theme context
  const themeContext = useContext(ThemeContext);

  // Local states
  const [open, setOpen] = useState(false);

  // Scroll to top when sidebar open state changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [open]);

  return (
    <Container open={open}>
      <div className="content">
        <div className="top">
          <button
            type="button"
            className="hamburger"
            onClick={() => setOpen(!open)}
          >
            <MdKeyboardArrowLeft
              className="arrow"
              size={20}
              color={themeContext.font_high}
            />
            <MdDehaze size={30} color={themeContext.font_high} />
          </button>

          <div className="left">
            <img
              src={process.env.REACT_APP_CLIENT_LOGO_SM_WHITE_URL}
              alt="Cartão Digital"
            />
            <div className="title">
              {t('@header_module/DIGITAL_CARD', 'Cartão Digital')}
            </div>
          </div>

          <div className="right">
            <div className="profile">
              <Avatar
                name={person?.s_nome || ''}
                size={50}
                src={person?.s_avatar}
              />

              <div className="profileName">
                <span className="name">
                  {person?.i_sexo === 0
                    ? t('@header_module/WELCOME_female', 'Bem-vinda,')
                    : t('@header_module/WELCOME_male', 'Bem-vindo,')}
                </span>
                <strong>{person?.s_nome}</strong>
              </div>
            </div>

            <button
              type="button"
              onClick={() => {
                setOpen(false);
                dispatch(signOut());
              }}
            >
              <MdExitToApp size={36} color={themeContext.font_high} />
            </button>
          </div>
        </div>

        <div className="navigation">
          <nav>
            <ul>
              <li>
                <Link
                  to="/dashboard"
                  onClick={() => setOpen(false)}
                  className={pathname.includes('dashboard') ? 'active' : ''}
                >
                  <MdHome size={24} color={themeContext.font_high} />
                  <span>{t('@header_module/HOME', 'Início')}</span>
                </Link>
              </li>

              {!!user?.card_created_at &&
                !!user?.gift_opened_at &&
                !!user?.trial_expires_at && (
                  <li>
                    <Link
                      to="/statistics"
                      onClick={() => setOpen(false)}
                      className={
                        pathname.includes('statistics') ? 'active' : ''
                      }
                    >
                      <MdEqualizer size={24} color={themeContext.font_high} />
                      <span>
                        {t('@header_module/STATISTICS', 'Estatísticas')}
                      </span>
                    </Link>
                  </li>
                )}

              {!user?.card_created_at && (
                <li>
                  <Link
                    to="/card"
                    onClick={() => setOpen(false)}
                    className={pathname.includes('card') ? 'active' : ''}
                  >
                    <MdBuild size={24} color={themeContext.font_high} />
                    <span>
                      {t('@header_module/CREATE_CARD', 'Criar Cartão')}
                    </span>
                  </Link>
                </li>
              )}

              {cards?.length > 1 &&
                !!user?.card_created_at &&
                !!user?.gift_opened_at &&
                !!user?.trial_expires_at && (
                  <li>
                    <Link
                      to="/cards"
                      onClick={() => setOpen(false)}
                      className={pathname.includes('cards') ? 'active' : ''}
                    >
                      <FaIdCardAlt size={24} color={themeContext.font_high} />
                      <span>
                        {t('@header_module/MY_CARDS', 'Meus Cartões')}
                      </span>
                    </Link>
                  </li>
                )}

              <li>
                <Link
                  to="/dependents"
                  onClick={() => setOpen(false)}
                  className={pathname.includes('dependents') ? 'active' : ''}
                >
                  <FaUsers size={22} color={themeContext.font_high} />
                  <span>{t('@header_module/DEPENDENTS', 'Dependentes')}</span>
                </Link>
              </li>

              <li>
                <Link
                  to="/about"
                  onClick={() => setOpen(false)}
                  className={pathname.includes('about') ? 'active' : ''}
                >
                  <FaInfoCircle size={22} color={themeContext.font_high} />
                  <span>{t('@header_module/ABOUT', 'Sobre')}</span>
                </Link>
              </li>
            </ul>
          </nav>

          <div className="cardSelector">
            <div className="profile">
              <Avatar
                name={person?.s_nome || ''}
                size={50}
                src={person?.s_avatar}
              />

              <div className="profileName">
                <span className="name">
                  {person?.i_sexo === 0
                    ? t('@header_module/WELCOME_female', 'Bem-vinda,')
                    : t('@header_module/WELCOME_male', 'Bem-vindo,')}
                </span>
                <strong>{person?.s_nome}</strong>
              </div>
            </div>
            <div className="cardIdentity">
              <MdPermIdentity size={24} color={themeContext.font_high} />
              <span>
                {t('@header_module/DIGITAL_CARD_OWNER', 'Cartão Digital de')}
                <strong>
                  &nbsp;
                  {person?.s_nome}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Header;
