import { rgba } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;

  span {
    font-weight: 500;
    color: ${({ theme }) => theme.font_warning};
  }
`;

export const GiftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  justify-content: center;

  h2 {
    color: ${({ theme }) => theme.font_danger};
    font-size: 2.25rem;
    margin-bottom: 1.438rem;
    text-align: center;
  }

  p {
    font-size: 1.125rem;
    color: ${({ theme }) => theme.font_secondary};
    text-align: center;
  }

  > button {
    margin-top: 2.188rem;
    max-width: 70vw;
  }
`;

export const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  align-self: stretch;
  margin-top: 2rem;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    max-width: 62.5rem;
    border-radius: 0.5rem;
    padding: 1rem;

    background-color: ${({ theme }) => theme.background_high};
    filter: ${({ theme }) =>
      `drop-shadow(0px 0.0625rem 0.125rem ${rgba(
        theme.background_low,
        0.25,
      )})`};

    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      align-self: stretch;

      gap: 1rem;
      margin-top: 1rem;

      .video {
        width: 100%;

        iframe {
          border-radius: 0.5rem;
          width: 100%;
        }
      }

      .controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        ul {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 1rem;
          min-width: 16.35rem;

          li {
            button {
              text-align: left;
              font-size: 1.1rem;
              color: ${({ theme }) => theme.font_secondary};
              transition: 0.25s;

              &.active {
                color: ${({ theme }) => theme.button_secondary};
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    > div {
      .content {
        flex-direction: column;

        .video {
          order: 1;
        }

        .controls {
          order: 0;
        }
      }
    }
  }
`;
