import React, { useCallback, useState, useContext, useMemo } from 'react';
import { FaGift } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { differenceInDays, parseISO } from 'date-fns';
import Lottie from 'lottie-react-web';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Asset import
import animation from '../../assets/gift-animation.json';

// Service import
import api from '../../services/api';

// Interface import
import { IApplicationState } from '../../store';

// Style import
import { Container, GiftContainer, VideoContainer } from './styles';

// Component import
import Alert from '../../components/Alert';
import Button from '../../components/Form/Button';
import Card from '../../components/Card';
import { refreshSession } from '../../store/modules/auth/actions';

const Dashboard: React.FC = () => {
  // History hook
  const history = useHistory();

  // Get translation function
  const { t } = useTranslation();

  // Dispatch hook
  const dispatch = useDispatch();

  // Theme context
  const themeContext = useContext(ThemeContext);

  // Global states
  const user_data = useSelector((state: IApplicationState) => state.auth);
  const user_cards = useSelector((state: IApplicationState) => state.card.list);

  // Local states
  const [loading, setLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(0);

  // Trial difference in days
  const trial_difference = Math.abs(
    differenceInDays(
      new Date(),
      parseISO(String(user_data.user?.trial_expires_at)),
    ),
  );

  // Client videos
  const videos = useMemo(
    () => [
      process.env.REACT_APP_CLIENT_VIDEO_TUTORIAL_1_YOUTUBE_ID,
      process.env.REACT_APP_CLIENT_VIDEO_TUTORIAL_2_YOUTUBE_ID,
      process.env.REACT_APP_CLIENT_VIDEO_TUTORIAL_3_YOUTUBE_ID,
      process.env.REACT_APP_CLIENT_VIDEO_TUTORIAL_4_YOUTUBE_ID,
      process.env.REACT_APP_CLIENT_VIDEO_TUTORIAL_5_YOUTUBE_ID,
      process.env.REACT_APP_CLIENT_VIDEO_TUTORIAL_6_YOUTUBE_ID,
    ],
    [],
  );

  // Submit gift
  const handleGiftSubmit = useCallback(async () => {
    try {
      // Loading set
      setLoading(false);

      // API call
      await api.patch('card/open-gift');

      // Display message to user
      toast.success(
        t(
          '@dashboard/CARD_CREATED_SUCCESSFULLY',
          'Processo concluído com sucesso. Cartão enviado para seu e-mail. Verifique também sua caixa de spam caso não encontre-o em sua caixa de entrada.',
        ),
        {
          position: 'top-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );

      // Set the images
      dispatch(refreshSession());

      // Loading set
      setLoading(false);
    } catch (err) {
      // Check if server returned a error
      if (err.response?.data?.message) toast.error(err.response.data.message);
      // Display communication error to user
      else
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );

      // Loading set
      setLoading(false);
    }
  }, [t, dispatch]);

  return (
    <Container>
      {user_data.user?.card_created_at || user_data.user?.trial_expires_at ? (
        user_data.user?.gift_opened_at ? (
          <>
            <Alert type="warning">
              <MdDateRange size={36} color={themeContext.font_warning} />
              <div>
                <strong>
                  {t(
                    '@dashboard/SYSTEM_TITLE',
                    'Sistema de monitoramento do cartão',
                  )}
                </strong>
                {' - '}
                {t(
                  '@dashboard/FINISH_MONITOR',
                  'Seu período de degustação se encerrará em',
                )}
                <span>{` ${trial_difference + 1} ${
                  trial_difference > 0 ? 'dias' : 'dia'
                }`}</span>
              </div>
            </Alert>
            <Card id={user_data.user?.selected_card_id || user_cards[0].id} />
          </>
        ) : (
          <GiftContainer>
            <h2>{t('@dashboard/CONGRATULATIONS', 'Parabéns')}</h2>
            <p>
              {t(
                '@dashboard/CONGRATULATIONS_P1',
                'Você concluiu com sucesso a formatação e ativação do seu Cartão Digital.',
              )}
            </p>
            <p>
              {t(
                '@dashboard/CONGRATULATIONS_P2',
                'Clique na caixa abaixo para visualizar o seu presente da {{ client }}.',
                {
                  client: process.env.REACT_APP_CLIENT_SHORT_NAME,
                  context: process.env.REACT_APP_CLIENT_GENDER,
                },
              )}
            </p>
            <button
              type="button"
              disabled={loading || user_data.loading}
              onClick={() => handleGiftSubmit()}
            >
              <Lottie
                options={{
                  animationData: animation,
                }}
              />
            </button>
          </GiftContainer>
        )
      ) : (
        <>
          <Alert type="warning">
            <FaGift size={36} color="#FC7D35" />
            <div>
              {t(
                '@dashboard/CARD_NOT_CREATED',
                'Você ainda não criou seu Cartão Digital. Clique no botão ao lado e crie seu cartão. É rápido e fácil !!!',
              )}
            </div>
            <Button colorType="primary" onClick={() => history.push('/card')}>
              {t('@dashboard/CREATE_CARD', 'CRIAR CARTÃO DIGITAL')}
            </Button>
          </Alert>
          <VideoContainer>
            <div>
              <h1>
                {
                  (t('@dashboard/VIDEO_TITLE'),
                  'Tutorial - Veja aqui como criar o seu Cartão Digital')
                }
              </h1>
              <div className="content">
                <div className="video">
                  <iframe
                    className="video"
                    width="853"
                    height="480"
                    src={`https://www.youtube.com/embed/${videos[selectedVideo]}`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Video"
                  />
                </div>
                <div className="controls">
                  <ul>
                    <li>
                      <button
                        type="button"
                        onClick={() => setSelectedVideo(0)}
                        className={selectedVideo === 0 ? 'active' : ''}
                      >
                        &bull;{' '}
                        {t('@dashboard/VIDEO_STEP_1', 'Etapa 1 - Modelo')}
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => setSelectedVideo(1)}
                        className={selectedVideo === 1 ? 'active' : ''}
                      >
                        &bull;{' '}
                        {t(
                          '@dashboard/VIDEO_STEP_2',
                          'Etapa 2 - Preenchimento',
                        )}
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => setSelectedVideo(2)}
                        className={selectedVideo === 2 ? 'active' : ''}
                      >
                        &bull;{' '}
                        {t('@dashboard/VIDEO_STEP_3', 'Etapa 3 - Ajustes')}
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => setSelectedVideo(3)}
                        className={selectedVideo === 3 ? 'active' : ''}
                      >
                        &bull;{' '}
                        {t('@dashboard/VIDEO_STEP_4', 'Etapa 4 - Confirmação')}
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => setSelectedVideo(4)}
                        className={selectedVideo === 4 ? 'active' : ''}
                      >
                        &bull;{' '}
                        {t(
                          '@dashboard/VIDEO_STEP_5',
                          'Etapa 5 - Ativação e Estatísticas',
                        )}
                      </button>
                    </li>
                    <li>
                      <button
                        type="button"
                        onClick={() => setSelectedVideo(5)}
                        className={selectedVideo === 5 ? 'active' : ''}
                      >
                        &bull; {t('@dashboard/VIDEO_STEP_6', 'Vídeo completo')}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </VideoContainer>
        </>
      )}
    </Container>
  );
};

export default Dashboard;
