import { rgba } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;

  .content {
    max-width: 93.75rem;
    width: 100%;
    background-color: ${({ theme }) => theme.background_high};
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: center;
    padding: 0.9375rem;
    border-radius: 0.3125rem;
    filter: ${props => css`
        drop-shadow(0px 0.0625rem 0.125rem ${rgba(
          props.theme.background_low,
          0.25,
        )})
        `};
  }
`;
