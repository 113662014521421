import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Markdown from 'react-markdown';
import { CircularProgress } from '@material-ui/core';

// Style import
import { Container } from './styles';

const About: React.FC = () => {
  // Get translation function
  const { t } = useTranslation();

  // Local states
  const [file, setFile] = useState<string>('');

  // Get files to load
  useEffect(() => {
    (async function loadFiles() {
      try {
        const load = await fetch(`texts/card_activation_contract.md`);
        const text = await load.text();

        setFile(text);
      } catch (err) {
        setFile('');
        toast.error(
          t(
            '@general/CONNECTION_FAILURE_ERROR',
            'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
          ),
        );
      }
    })();
  }, [t]);

  return (
    <Container>
      <div className="content">
        <h1>Termos de Utilização</h1>
        {file ? (
          <div className="text">
            <Markdown source={file} />
          </div>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
      </div>
    </Container>
  );
};

export default About;
