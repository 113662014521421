import { rgba } from 'polished';
import styled, { css } from 'styled-components';

interface IAlertProps {
  type?: 'success' | 'warning' | 'danger' | 'info';
}

const alertTypeVariations = {
  info: css`
    background-color: ${({ theme }) => theme.background_info};
  `,
  success: css`
    background-color: ${({ theme }) => theme.background_success};
  `,
  warning: css`
    background-color: ${({ theme }) => theme.background_warning};
  `,
  danger: css`
    background-color: ${({ theme }) => theme.background_danger};
  `,
};

export const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.background_high};
  width: 100%;
  display: flex;
  filter: ${({ theme }) =>
    `drop-shadow(0px 0.0625rem 0.125rem ${rgba(theme.background_low, 0.25)})`};
`;

export const AlertStrip = styled.div<IAlertProps>`
  width: 0.625rem;
  height: auto;
  ${props => alertTypeVariations[props.type || 'info']}
`;

export const AlertContainer = styled.div`
  padding: 0.625rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 1.25rem;
  flex: 1;

  @media screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;

    text-align: center;
  }
`;
