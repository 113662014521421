import React from 'react';
import { useTranslation } from 'react-i18next';

// Style import
import { Container } from './styles';

const Dependents: React.FC = () => {
  // Get translation function
  const { t } = useTranslation();

  return (
    <Container>
      <div className="content">
        {t(
          '@dependents_page/SOON',
          'Em breve seus dependentes terão acesso a plataforma de Cartões Digitais.',
        )}
      </div>
    </Container>
  );
};

export default Dependents;
