import React, { useState, useEffect, useCallback } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import {
  isValid,
  isAfter,
  differenceInYears,
  differenceInDays,
  format,
  subMonths,
  differenceInCalendarDays,
  addDays,
  differenceInMonths,
  addMonths,
  parse,
  parseISO,
  endOfDay,
  // eslint-disable-next-line import/no-duplicates
} from 'date-fns';
import {
  FaFacebook,
  FaWhatsapp,
  FaTwitter,
  FaYoutube,
  FaInstagram,
  FaLinkedin,
  FaGlobeAmericas,
  FaMapMarkedAlt,
} from 'react-icons/fa';
import { MdInfo } from 'react-icons/md';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Component import
import DatePicker from '../../components/Form/DatePicker';
import Card from '../../components/Card';
import LineChart from '../../components/Charts/Line';
import Button from '../../components/Form/Button';
import Alert from '../../components/Alert';

// Style import
import { Container, AlertContent, SocialCard } from './styles';

// Service import
import api from '../../services/api';

// Store import
import { updateStatisticsRequest } from '../../store/modules/statistics/actions';
import { IApplicationState } from '../../store';

// Interface import
interface IPeriodResponse {
  total: number;
  whatsapp: number;
  facebook: number;
  instagram: number;
  twitter: number;
  linkedin: number;
  youtube: number;
  website: number;
  address: number;
}

interface IPeriodData {
  x: string;
  y: number;
}

interface IPeriodCount {
  id: string;
  color: string;
  data: {
    [key: string]: {
      x: string;
      y: number;
    };
  };
}

interface IPeriodGraph {
  id: string;
  color: string;
  data: IPeriodData[];
}

interface IStatisticsGraph {
  method: 'days' | 'months';
  total: number;
  general: {
    whatsapp: number;
    facebook: number;
    instagram: number;
    twitter: number;
    linkedin: number;
    youtube: number;
    website: number;
    address: number;
  };
  each: {
    [key: string]: {
      type: string;
      total: number;
    };
  };
  period: IPeriodGraph[];
}

interface IUpdateData {
  start_date: Date;
  end_date: Date;
}

interface IPeriodForm {
  startDate: string;
  endDate: string;
}

interface IStatisticsParams {
  id: string;
}

const Statistics: React.FC = () => {
  // Get route params
  const { id } = useParams<IStatisticsParams>();

  // Get translation function
  const { t } = useTranslation();

  // History  hook
  const history = useHistory();

  // Dispatch hook
  const dispatch = useDispatch();

  // Global states
  const user = useSelector((state: IApplicationState) => state.auth.user);
  const cards = useSelector((state: IApplicationState) => state.card.list);

  // Local refs
  const formRef = React.useRef<FormHandles>(null);
  const graphSectionRef = React.useRef<HTMLDivElement>(null);

  // Get selected card
  const selectedCard = cards.find(card => card.id === id);

  // Local states
  const [graphData, setGraphData] = useState<IStatisticsGraph | undefined>(
    undefined,
  );
  const [loading, setLoading] = useState(false);

  // If user card was not created, redirect to dashboard
  if (!user?.card_created_at || !user?.gift_opened_at) {
    history.push('/dashboard');
  }

  // If card not found
  if (id && !selectedCard) {
    toast.error(t('@statistics/CARD_NOT_FOUND', 'Cartão não encontrado.'));
    history.push('/dashboard');
  }

  // Change data period
  const updateData = useCallback(
    async ({ start_date, end_date }: IUpdateData) => {
      // Update all statistics
      dispatch(updateStatisticsRequest());

      try {
        // Validate dates
        if (!isValid(start_date) || !isValid(end_date)) {
          toast.error(t('@statistics/INVALID_DATES', 'Datas inválidas'));
          return;
        }

        // Check if start_date is after end_date
        if (isAfter(start_date, end_date)) {
          toast.error(
            t(
              '@statistics/START_DATE_AFTER',
              'A data final deve estar após a data inicial.',
            ),
          );
          return;
        }

        // Check if end date is after now
        if (isAfter(end_date, endOfDay(new Date()))) {
          toast.error(
            t(
              '@statistics/FUTURE_DATE',
              'Não é possível gerar estatísticas para o futuro de seu cartão.',
            ),
          );
          return;
        }

        // Define one year limit
        if (Math.abs(differenceInYears(start_date, end_date)) >= 1) {
          toast.error(
            t(
              '@statistics/MAXIMUM_YEAR',
              'O período deve respeitar o limite de um ano.',
            ),
          );
          return;
        }

        // Loading set
        setLoading(true);

        // API call
        const response = await api.get('statistics', {
          params: {
            ...(selectedCard?.id && {
              card_id: selectedCard.id,
            }),
            ...(start_date &&
              end_date && {
                start_date,
                end_date,
              }),
          },
        });

        // Switch the graphic between days or months
        const showDays = Math.abs(differenceInDays(start_date, end_date)) <= 31;

        // Setup table colors
        const colors = {
          whatsapp: '#128c7e',
          facebook: '#3b5998',
          instagram: '#f56040',
          twitter: '#1da1f2',
          linkedin: '#0077b5',
          youtube: '#da3535',
          website: '#999999',
          address: '#ffae00',
        };

        // Setup period count
        const period: IPeriodCount[] = [
          {
            id: t('@statistics/WHATSAPP', 'WhatsApp'),
            color: colors.whatsapp,
            data: {},
          },
          {
            id: t('@statistics/FACEBOOK', 'Facebook'),
            color: colors.facebook,
            data: {},
          },
          {
            id: t('@statistics/INSTAGRAM', 'Instagram'),
            color: colors.instagram,
            data: {},
          },
          {
            id: t('@statistics/TWITTER', 'Twitter'),
            color: colors.twitter,
            data: {},
          },
          {
            id: t('@statistics/LINKEDIN', 'LinkedIn'),
            color: colors.linkedin,
            data: {},
          },
          {
            id: t('@statistics/YOUTUBE', 'YouTube'),
            color: colors.youtube,
            data: {},
          },
          {
            id: t('@statistics/WEBSITE', 'Website'),
            color: colors.website,
            data: {},
          },
          {
            id: t('@statistics/ADDRESS', 'Endereço'),
            color: colors.address,
            data: {},
          },
        ];

        // In days graph
        if (showDays) {
          // Calc the difference in days
          const diff_days = Math.abs(
            differenceInCalendarDays(start_date, end_date),
          );

          // Iterate over days to create initial value (0)
          for (let i = 0; i <= diff_days; i += 1) {
            const date = format(addDays(start_date, i), 'dd/MM/yyy');
            period[0].data[date] = {
              x: date,
              y: 0,
            };
            period[1].data[date] = {
              x: date,
              y: 0,
            };
            period[2].data[date] = {
              x: date,
              y: 0,
            };
            period[3].data[date] = {
              x: date,
              y: 0,
            };
            period[4].data[date] = {
              x: date,
              y: 0,
            };
            period[5].data[date] = {
              x: date,
              y: 0,
            };
            period[6].data[date] = {
              x: date,
              y: 0,
            };
            period[7].data[date] = {
              x: date,
              y: 0,
            };
          }

          // Iterate over entries
          Object.entries<IPeriodResponse>(response.data.period).forEach(
            ([key, value]) => {
              // Create date
              const num = key.split('-');
              const date = {
                date: new Date(Number(num[0]), Number(num[1]), Number(num[2])),
                string: `${num[2].padStart(2, '0')}/${String(
                  Number(num[1]) + 1,
                ).padStart(2, '0')}/${num[0].padStart(4, '0')}`,
              };

              // Sum numbers
              period[0].data[date.string].y += value.whatsapp;
              period[1].data[date.string].y += value.facebook;
              period[2].data[date.string].y += value.instagram;
              period[3].data[date.string].y += value.twitter;
              period[4].data[date.string].y += value.linkedin;
              period[5].data[date.string].y += value.youtube;
              period[6].data[date.string].y += value.website;
              period[7].data[date.string].y += value.address;
            },
          );
        } else {
          // Difference in months
          const diff_months = Math.abs(
            differenceInMonths(start_date, end_date),
          );

          // Iterate to initial value (0)
          for (let i = 0; i <= diff_months; i += 1) {
            const date = format(addMonths(start_date, i), 'MMM/yyy', {
              locale: ptBR,
            });
            period[0].data[date] = {
              x: date,
              y: 0,
            };
            period[1].data[date] = {
              x: date,
              y: 0,
            };
            period[2].data[date] = {
              x: date,
              y: 0,
            };
            period[3].data[date] = {
              x: date,
              y: 0,
            };
            period[4].data[date] = {
              x: date,
              y: 0,
            };
            period[5].data[date] = {
              x: date,
              y: 0,
            };
            period[6].data[date] = {
              x: date,
              y: 0,
            };
            period[7].data[date] = {
              x: date,
              y: 0,
            };
          }

          // Iterate over entries
          Object.entries<IPeriodResponse>(response.data.period).forEach(
            ([key, value]) => {
              // Create date
              const num = key.split('-');
              const date = {
                date: new Date(Number(num[0]), Number(num[1]), Number(num[2])),
                string: format(
                  new Date(Number(num[0]), Number(num[1]), Number(num[2])),
                  'MMM/yyyy',
                  {
                    locale: ptBR,
                  },
                ),
              };

              // Sum numbers
              period[0].data[date.string].y += value.whatsapp;
              period[1].data[date.string].y += value.facebook;
              period[2].data[date.string].y += value.instagram;
              period[3].data[date.string].y += value.twitter;
              period[4].data[date.string].y += value.linkedin;
              period[5].data[date.string].y += value.youtube;
              period[6].data[date.string].y += value.website;
              period[7].data[date.string].y += value.address;
            },
          );
        }

        // Mount graphic data
        const mount: IStatisticsGraph = {
          method: showDays ? 'days' : 'months',
          total: response.data.total,
          general: response.data.general,
          each: response.data.each,
          period: period.map(per => ({
            id: per.id,
            color: per.color,
            data: Object.values(per.data),
          })),
        };

        // Set states
        setGraphData(mount);
        setLoading(false);
      } catch (err) {
        // Check if server has returned a error message
        if (err.response?.data?.message) toast.error(err.response.data.message);
        // Display communication error to client
        else
          toast.error(
            t(
              '@general/CONNECTION_FAILURE_ERROR',
              'Falha ao comunicar-se com o servidor, verifique a sua conexão.',
            ),
          );

        // Loading set
        setLoading(false);
      }
    },
    [t, selectedCard, dispatch],
  );

  // Initial call
  useEffect(() => {
    updateData({ start_date: subMonths(new Date(), 1), end_date: new Date() });
    dispatch(updateStatisticsRequest());
  }, [selectedCard, dispatch, updateData]);

  // Submit function to get statistics
  const handleSubmit = useCallback(
    (data: IPeriodForm): void => {
      const start_date = parse(data.startDate, 'yyyy-MM-dd', new Date());
      const end_date = parse(data.endDate, 'yyyy-MM-dd', new Date());
      updateData({
        start_date,
        end_date,
      });
    },
    [updateData],
  );

  return (
    <Container>
      {!!selectedCard?.id && cards.length > 1 ? (
        <Alert type="info">
          <AlertContent>
            <div>
              <MdInfo size={36} color="#76bffa" />
              <div>
                {t(
                  '@statistics/SHOWING_STATISTICS',
                  'Exibindo informações do cartão criado em',
                )}{' '}
                {format(parseISO(selectedCard?.created_at), 'dd/MM/yyyy')}.
              </div>
            </div>
            <div>
              <Button colorType="info" onClick={() => history.push('/cards')}>
                {t('@statistics/SELECT_CARD', 'Selecionar Cartão')}
              </Button>
              <Button
                colorType="info"
                onClick={() => history.push('/statistics')}
              >
                {t(
                  '@statistics/GOTO_GENERAL_STATISTICS',
                  'Visualizar Estatisticas Gerais',
                )}
              </Button>
            </div>
          </AlertContent>
        </Alert>
      ) : (
        <Alert type="info">
          <AlertContent>
            <div>
              <MdInfo size={36} color="#76bffa" />
              <div>
                {t(
                  '@statistics/SHOWING_ALL_CARDS_STATISTICS',
                  'Exibindo estatísticas de todos os cartões.',
                )}
              </div>
            </div>
            {cards.length > 1 && (
              <div className="actions">
                <Button colorType="info" onClick={() => history.push('/cards')}>
                  {t('@statistics/SELECT_CARD', 'Selecionar Cartão')}
                </Button>
              </div>
            )}
          </AlertContent>
        </Alert>
      )}
      <div className="upper-container">
        {selectedCard?.id && <Card hideStatistics id={selectedCard.id} />}
        <div>
          <div className="form-data">
            <div className="title">
              {t('@statistics/DATE_SELECTION_TITLE', 'Período de Análise')}
            </div>
            <Form
              onSubmit={handleSubmit}
              ref={formRef}
              className="select-dates"
            >
              <DatePicker
                name="startDate"
                label={t('@statistics/START_DATE', 'Data Inicial')}
                margin="none"
                value={format(subMonths(new Date(), 1), 'yyyy-MM-dd')}
              />
              <DatePicker
                name="endDate"
                label={t('@statistics/END_DATE', 'Data Final')}
                margin="none"
                value={format(new Date(), 'yyyy-MM-dd')}
              />
              <Button
                onClick={() => formRef.current?.submitForm()}
                colorType="primary"
                size="medium"
                loading={loading}
              >
                {t('@statistics/SEARCH', 'Pesquisar')}
              </Button>
            </Form>
          </div>

          <section className="count">
            <SocialCard className="facebook">
              <FaFacebook size={36} />
              <div className="title">
                {t('@statistics/CLICKS_ON_FACEBOOK', 'Cliques em Facebook')}
              </div>
              <div className="value">{graphData?.general.facebook || 0}</div>
            </SocialCard>
            <SocialCard className="twitter">
              <FaTwitter size={36} />
              <div className="title">
                {t('@statistics/CLICKS_ON_TWITTER', 'Cliques em Twitter')}
              </div>
              <div className="value">{graphData?.general.twitter || 0}</div>
            </SocialCard>
            <SocialCard className="instagram">
              <FaInstagram size={36} />
              <div className="title">
                {t('@statistics/CLICKS_ON_INSTAGRAM', 'Cliques em Instagram')}
              </div>
              <div className="value">{graphData?.general.instagram || 0}</div>
            </SocialCard>
            <SocialCard className="youtube">
              <FaYoutube size={36} />
              <div className="title">
                {t('@statistics/CLICKS_ON_YOUTUBE', 'Cliques em YouTube')}
              </div>
              <div className="value">{graphData?.general.youtube || 0}</div>
            </SocialCard>
            <SocialCard className="linkedin">
              <FaLinkedin size={36} />
              <div className="title">
                {t('@statistics/CLICKS_ON_LINKEDIN', 'Cliques em LinkedIn')}
              </div>
              <div className="value">{graphData?.general.linkedin || 0}</div>
            </SocialCard>
            <SocialCard className="whatsapp">
              <FaWhatsapp size={36} />
              <div className="title">
                {t('@statistics/CLICKS_ON_WHATSAPP', 'Cliques em WhatsApp')}
              </div>
              <div className="value">{graphData?.general.whatsapp || 0}</div>
            </SocialCard>
            <SocialCard className="website">
              <FaGlobeAmericas size={36} />
              <div className="title">
                {t('@statistics/CLICKS_ON_WEBSITE', 'Cliques em Website')}
              </div>
              <div className="value">{graphData?.general.website || 0}</div>
            </SocialCard>
            <SocialCard className="address">
              <FaMapMarkedAlt size={36} />
              <div className="title">
                {t('@statistics/CLICKS_ON_ADDRESS', 'Cliques em Endereço')}
              </div>
              <div className="value">{graphData?.general.address || 0}</div>
            </SocialCard>
          </section>
          <SocialCard className="other">
            <div className="title">
              {t(
                '@statistics/TOTAL_CLICKS_ON_PERIOD',
                'Total de cliques neste período',
              )}
            </div>
            <div className="value">{graphData?.total || 0}</div>
          </SocialCard>

          {(graphData?.each || (graphData?.period && !loading)) && (
            <div className="access-graph">
              <Button
                colorType="primary"
                onClick={() => graphSectionRef.current?.scrollIntoView()}
              >
                Acessar Gráficos
              </Button>
            </div>
          )}
        </div>
      </div>

      {graphData?.each && (
        <div className="form-data" ref={graphSectionRef}>
          <div className="title">
            {t(
              '@statistics/TOTAL_CLICKS_PER_LINK',
              'Total de cliques por links',
            )}
          </div>
          <div className="table-container">
            <Table className="table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>{t('@statistics/CLICK_TYPE', 'Tipo')}</TableCell>
                  <TableCell>{t('@statistics/CLICK_LINK', 'Link')}</TableCell>
                  <TableCell align="center">
                    {t('@statistics/CLICK_COUNT', 'Cliques')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(graphData.each).map(([key, value], index) => {
                  let type;
                  switch (value.type) {
                    case 'whatsapp':
                      type = t('@statistics/WHATSAPP', 'WhatsApp');
                      break;

                    case 'facebook':
                      type = t('@statistics/FACEBOOK', 'Facebook');
                      break;

                    case 'instagram':
                      type = t('@statistics/INSTAGRAM', 'Instagram');
                      break;

                    case 'twitter':
                      type = t('@statistics/TWITTER', 'Twitter');
                      break;

                    case 'linkedin':
                      type = t('@statistics/LINKEDIN', 'LinkedIn');
                      break;

                    case 'youtube':
                      type = t('@statistics/YOUTUBE', 'YouTube');
                      break;

                    case 'website':
                      type = t('@statistics/WEBSITE', 'Website');
                      break;

                    case 'address':
                      type = t('@statistics/ADDRESS', 'Endereço');
                      break;

                    default:
                      type = '';
                  }

                  return (
                    <TableRow
                      key={key}
                      className={index % 2 === 0 ? 'odd' : 'even'}
                    >
                      <TableCell component="th" scope="row">
                        <div className={value.type}>{type}</div>
                      </TableCell>
                      <TableCell>{key}</TableCell>
                      <TableCell align="center">{value.total}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>
      )}

      {graphData?.period && !loading && (
        <div className="chart-container">
          <LineChart
            xLegend={
              graphData?.method === 'days'
                ? t('@statistics/GRAPH_CLICK_DAYS', 'Dias')
                : t('@statistics/GRAPH_CLICK_MONTHS', 'Meses')
            }
            yLegend={t('@statistics/GRAPH_CLICK_LEGEND', 'Cliques')}
            xLegendVertical={graphData?.method === 'days'}
            data={graphData.period}
            title={t('@statistics/GRAPH_TITLE', 'Total de cliques por período')}
          />
        </div>
      )}
    </Container>
  );
};

export default Statistics;
